"use client";
import { notFound, usePathname } from "next/navigation";
import { useEffect, useMemo } from "react";

import Link from "next/link";
import useUIState from "hooks/useUIState";
import { Tiles } from "types/apiTypes";
import { matter } from "app/fonts";
import useConfetti from "hooks/useconfetti";
import CitationOverlay from "./TileComponents/CitationOverlay";
import SortedTiles from "./SortedTiles";

type TileBuilderProps = {
  tiles?: Tiles;
  className?: string;
  classNameContainer?: string;
  showEditButton?: boolean;
};

const TilesPage = ({ tiles, className, classNameContainer, showEditButton }: TileBuilderProps) => {
  const pathname = usePathname();
  const { updateChildBackPagePath, updateUIState } = useUIState();
  const { showConfetti } = useConfetti();

  useEffect(() => {
    updateUIState({
      disabledDefaultWebsiteHeaderFooter: tiles?.additional_config?.disableDefaultHeaderFooter,
      websiteTileLanguage: tiles?.language
    });
  }, [tiles?.additional_config?.disableDefaultHeaderFooter, updateUIState, tiles?.language]);

  const hasPromoBar = useMemo(() => {
    return tiles?.tiles_components?.some((component) => component.component_id?.name === "PromoBar");
  }, [tiles]);

  // Sort the tiles_components array based on the 'order' value
  const sortedTilesComponents = useMemo(() => {
    if (!tiles?.tiles_components) return [];
    return tiles?.tiles_components
      ?.filter((comp) => !comp.is_deleted)
      ?.sort((a, b) => {
        return (a.order || 0) - (b.order || 0);
      });
  }, [tiles]);

  useEffect(() => {
    if (tiles?.show_confetti_on_load) {
      showConfetti();
    }
  }, [showConfetti, tiles?.show_confetti_on_load]);

  if (!tiles) {
    return notFound();
  }

  return (
    <div className={classNameContainer}>
      <CitationOverlay isEnabled={!!tiles?.intro_display} tiles={tiles} introMessage={tiles?.intro_message || ""}>
        <div className={className} id="tile Page">
          <div className="font-matter">
            <div className={`${matter.className}`}>
              {/* @ts-ignore */}
              <SortedTiles
                dealId={tiles.deal_id}
                tileComponents={sortedTilesComponents}
                hasPromoBar={hasPromoBar}
                slug={tiles.slug}
                language={tiles?.language}
              />
            </div>
          </div>
          {showEditButton && (
            <div className="fixed bottom-4 z-50 flex w-full justify-center">
              <Link
                href={`/admin/tiles/${tiles.id}`}
                onClick={() => {
                  pathname ? updateChildBackPagePath(pathname) : null;
                }}
                className="text-primary inline-flex h-11 w-fit scale-100 items-center justify-center gap-x-1 rounded-md bg-[#171717] px-4 text-sm font-medium text-[#ffffff] transition-transform hover:bg-[#383838] active:scale-[0.98] active:bg-primary-900 dark:bg-[#ededed] dark:text-[#0a0a0a] dark:hover:bg-[#cccccc]"
              >
                Edit Tile
              </Link>
            </div>
          )}
        </div>
      </CitationOverlay>
    </div>
  );
};

export default TilesPage;
